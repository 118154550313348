<template>
  <div className="main-content">
    <div style="font-size: 30px; font-weight: bold;text-align: center">
      欢迎登录{{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      title: this.$title,
    };
  },
};
</script>

<style scoped>
.avue-data-panel >>> .item {
  margin-bottom: 20px;
}

.avue-data-panel >>> .iconfont {
  font-size: 30px;
}
</style>
