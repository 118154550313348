const user = [
  // {
  //   path: "/user/manage",
  //   name: "commonManage",
  //   component: () => import("@/view/user/manage"),
  // },
  {
    path: "/user/level",
    name: "userLevel",
    component: () => import("@/view/user/level"),
  },
  {
    path: "/user/plan",
    name: "userPlanl",
    component: () => import("@/view/user/plan"),
  },
  {
    path: "/user/teacher",
    name: "userTeacher",
    component: () => import("@/view/user/teacher"),
  },
  {
    path: "/user/class",
    name: "userClass",
    component: () => import("@/view/user/class"),
  },
  {
    path: "/user/review",
    name: "userReview",
    component: () => import("@/view/user/review"),
  },

  {
    path: "/user/reviewProgress",
    name: "userReviewProgress",
    component: () => import("@/view/user/reviewProgress"),
  },
  {
    path: "/user/student",
    name: "userStudent",
    component: () => import("@/view/user/student"),
  },
];
export default user;
