<template>
  <el-dialog
    :title="title == 'add' ? '新增角色权限' : '修改角色权限'"
    :visible.sync="dialogVisible"
    width="60%"
    v-dialogdrag
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <avue-form ref="form" :option="option" v-model="form">
      <template slot="menuIds">
        <!-- <rolePower  :choiceId="choiceId" :tabs="tabs"/> -->
        <!-- onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)" -->
        <el-tree
          ref="tree"
          :props="props"
          :data="tabs"
          show-checkbox
          node-key="id"
          :default-expanded-keys="defaultCheckedKeys"
          :default-checked-keys="defaultCheckedKeys"
          @check-change="handleCheckChange"
        ></el-tree>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button :icon="clickType?'el-icon-circle-check':'el-icon-loading'" type="primary" size="small" @click="handleSubmit(clickType)">{{clickType?'确 定':'提交中'}}</el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="handleClose">取 消</el-button>
        </span> -->
      </template>
      <template slot="menuForm">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="formSubmit(form)">确认</el-button>
      </template>
    </avue-form>
  </el-dialog>
</template>

<script>
import { roleAdd, roleView, roleUpdate } from "@/api/system/role";
import { roleMenuView, roleMenuUpdate } from "@/api/system/role";
import rolePower from "@/components/system/role/power";
export default {
  name: "index",
  props: {
    title: String,
    choiceId: {
      default: "0",
      type: String,
    },
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    tabs: Array,
  },
  data() {
    return {
      form: {},
      props: {
        label: "name",
        value: "id",
        isLeaf: "leaf",
      },
      clickType: true,
      defaultCheckedKeys: [], //选择权限
      option: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "身份名称",
            prop: "name",
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            disabled:
              this.title == "add" || this.title == "edit" ? false : true,
            rules: [
              {
                required: true,
                message: "请输入身份名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            prop: "remarks",
            maxlength: 30,
            showWordLimit: true,
            span: 24,
            disabled:
              this.title == "add" || this.title == "edit" ? false : true,
            rules: [
              {
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "权限",
            prop: "menuIds",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.title == "edit") {
      this.roleData();
      //   roleMenuView(this.choiceId).then(res => {
      //             this.defaultCheckedKeys = res.data
      //         }).catch(err =>{
      //             this.$message.error(err.msg)
      //         })
    }
  },
  methods: {
    roleData() {
      roleView(this.choiceId)
        .then((res) => {
          // console.log(res)
          this.form = res.data;
          this.defaultCheckedKeys = res.data.menuIds;
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleClose() {
      // this.$confirm('确认关闭？')
      //     .then(res => {
      this.$root.eventHub.$emit("changeRole", false);
      // })
    },
    formSubmit(form, done) {
      if (this.title == "add") {
        return this.formSubmitRoleAdd(form, done);
      }
      if (this.title == "edit") {
        return this.formSubmitRoleUpdate(form, done);
      }
    },
    // 新增
    formSubmitRoleAdd(form, done) {
      roleAdd({
        code: form.code,
        name: form.name,
        state: form.state,
        remarks: form.remarks,
        menuIds: this.$refs.tree.getCheckedKeys(),
      })
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeRole", true);
            done();
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg);
          done();
        });
    },
    // 修改
    formSubmitRoleUpdate(form, done) {
      roleUpdate({
        id: this.choiceId,
        code: form.code,
        name: form.name,
        state: form.state,
        remarks: form.remarks,
        menuIds: this.$refs.tree.getCheckedKeys(),
      })
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$root.eventHub.$emit("changeRole", true);
            done();
          }
        })
        .catch((err) => {
          // this.$message.error(err.msg);
          done();
        });
    },

    //选择权限
    handleCheckChange(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate);
      this.expandArray = this.$refs.tree.getCheckedKeys();
    },
  },
};
</script>

<style scoped></style>
