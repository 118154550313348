import store from "@/store/index";
export default {
  SET_sessionStorage: function() {
    if (sessionStorage.getItem("store")) {
      store.replaceState(
        Object.assign(
          {},
          store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
      // sessionStorage.removeItem("store")
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(store.state));
    });
  },
  SET_localStorage: function() {
    // window.localStorage.clear(); //清除所有key
    window.sessionStorage.clear();
    // 退出后禁止返回上一页
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function() {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
  Set_store: function() {
    // store.index
    store.commit("setMenu", []);
    store.commit("setRouter", "");
    store.commit("setSubMenuIndex", "");
    store.commit("setMenuSubButtons", []);
    store.commit("setTableTabs", [
      {
        title: "首页",
        name: "0",
        url: "/",
        index: "0",
      },
    ]);

    // store.system
    store.commit("setRole", []);

    //store.user
    store.commit("setToken", "");
    store.commit("setUserInfo", {});
  },
};
