const course = [
  {
    path: "/course/list",
    name: "courseList",
    component: () => import("@/view/course/list"),
  },
  {
    path: "/course/classNumber",
    name: "courseClassNumber",
    component: () => import("@/view/course/classNumber"),
  },
  {
    path: "/course/review",
    name: "courseReview",
    component: () => import("@/view/course/review"),
  },
  {
    path: "/course/subject",
    name: "courseSubject",
    component: () => import("@/view/course/subject"),
  },
  {
    path: "/course/bibliography",
    name: "courseBibliography",
    component: () => import("@/view/course/bibliography"),
  },
];
export default course;
