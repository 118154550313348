import { userRole } from "@/api/system/index";

const system = {
  state: {
    role: [],
    parent: [],
  },
  mutations: {
    setRole(state, info) {
      state.role = info;
    },
    setParent(state, info) {
      state.parent = info;
    },
  },
  actions: {
    async getRole({ commit }) {
      const result = await userRole().then((res) => {
        return res.data;
      });
      commit("setRole", result);
    },
  },
};
export default system;
