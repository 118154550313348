import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// pages
// 登录
import login from "@/pages/login/login";
// 首页
import index from "@/pages/index/index";
import errorPage from "@/view/errorPage/index"; //403页面

// view 内容
// index首页
import indexHome from "@/view/index/index";

import user from "./view/user"; // user 用户管理
import sign from "./view/sign"; // 打卡记录
import activity from "./view/activity"; // 活动
import about from "./view/about"; // 关于我们
import course from "./view/course"; // 课程管理

//蓝信管理
// import lanxin from "./view/lanxin";

// system 系统管理
import systemUser from "@/view/system/user";
import systemRole from "@/view/system/role";
import systemMenu from "@/view/system/menu/list";

export default new Router({
  // mode: 'history',//去掉#号、
  mode: "hash", //保留#号、
  routes: [
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/",
      component: index,
      children: [
        // ...indexHome,
        {
          path: "/",
          name: "indexHome",
          component: indexHome,
        },
        {
          path: "/password",
          name: "password",
          component: () => import("@/pages/password/index"),
        },
        {
          path: "/info",
          name: "info",
          component: () => import("@/pages/info/index"),
        },
        {
          path: "/errorPage",
          name: "errorPage",
          component: errorPage,
        },
        // ------------------------------------------------------ 系统管理
        {
          path: "/system/user",
          name: "systemUser",
          component: systemUser,
        },
        {
          path: "/system/role",
          name: "role",
          component: systemRole,
        },
        ...user,
        ...sign,
        ...activity,
        ...about,
        ...course,

        {
          path: "/system/menu",
          name: "systemMenu",
          component: systemMenu,
        },
      ],
    },
  ],
});
