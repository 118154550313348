<template>
  <div class="indexNav">
    <el-tabs
      v-model="editableTabsValue"
      type="card"
      @tab-click="tabClick"
      @tab-remove="removeTab"
    >
      <el-tab-pane
        class="tab_after"
        v-for="(item, index) in editableTabs"
        :key="item.name"
        :label="item.title"
        :name="item.name"
        :closable="index != 0"
      >
        {{ item.content }}
      </el-tab-pane>
    </el-tabs>
    <el-dropdown trigger="click" @command="handleCommand">
      <el-button type="primary" size="small"
        >更多<i class="el-icon-arrow-down" style="padding-left: 10px"></i
      ></el-button>
      <el-dropdown-menu slot="dropdown">
        <!--                <el-dropdown-item command="a">关闭其他</el-dropdown-item>-->
        <el-dropdown-item command="b">关闭全部</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "indexNav",
  data() {
    return {
      editableTabsValue: "0",
      editableTabs: [],
    };
  },
  mounted() {
    let tableTabs = this.$store.state.index.tableTabs;
    this.editableTabs = tableTabs;
    let router = this.$store.state.index.router;
    this.editableTabs.forEach((item) => {
      if (item.url == router) {
        this.editableTabsValue = item.name;
      }
    });

    this.$root.eventHub.$on("changeTableTabs", (n) => {
      // console.log(n)
      this.editableTabsValue = n.data.name;
      if (!n.type) {
        let result = this.editableTabs.find((item) => {
          return item.name == n.data.name;
        });
        // console.log(result)
        if (result == undefined) {
          this.editableTabs.push(n.data);
          this.$store.commit("setTableTabs", this.editableTabs);
        }
      }
    });
  },
  methods: {
    tabClick(tab) {
      // console.log(tab.index)
      if (this.editableTabs[tab.index].url != this.$store.state.index.router) {
        this.$router.push(this.editableTabs[tab.index].url);
        this.editableTabsValue = this.editableTabs[tab.index].name;
        this.$root.eventHub.$emit(
          "changeSubMenuIndex",
          this.editableTabs[tab.index].index
        );
      }
      // console.log(this.editableTabsValue)
    },
    removeTab(targetName) {
      // console.log('targetName', targetName)
      // console.log('this.editableTabs', this.editableTabs)
      // console.log('this.editableTabsValue', this.editableTabsValue)
      if (targetName != this.editableTabsValue) {
        this.editableTabs.forEach((item, index) => {
          if (item.name === targetName) {
            this.editableTabs.splice(index, 1);
            // console.log(this.editableTabs)
          }
        });
      } else {
        this.editableTabs.forEach((item, index) => {
          if (item.name === targetName) {
            this.editableTabs.splice(index, 1);
            this.editableTabsValue = this.editableTabs[index - 1].name;
            this.$root.eventHub.$emit(
              "changeSubMenuIndex",
              this.editableTabs[index - 1].index
            );
            this.$router.push(this.editableTabs[index - 1].url);
          }
        });
      }
    },
    handleCommand(e) {
      let tabNav = [];
      // console.log(this.editableTabsValue)
      if (e == "a") {
        if (this.editableTabsValue == 0) {
          tabNav.push(this.editableTabs[0]);
          this.$router.push("/");
        } else {
          tabNav.push(this.editableTabs[0]);
          tabNav.push(this.editableTabs[this.editableTabsValue]);
        }
      } else {
        tabNav.push(this.editableTabs[0]);
        this.$router.push("/");
        this.editableTabsValue = "0";
      }
      this.editableTabs = tabNav;
      this.$store.commit("setTableTabs", tabNav);
    },
  },
};
</script>

<style scoped>
.indexNav {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
.indexNav >>> .el-tabs__header {
  margin-bottom: 0;
}
.indexNav >>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
.indexNav >>> .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}
.indexNav >>> .el-tabs--card > .el-tabs__header .is-active {
  position: relative;
}
.indexNav >>> .el-tabs--card > .el-tabs__header .is-active:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #409eff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.indexNav >>> .el-tabs--card > .el-tabs__header {
  border: none;
}
.el-tabs {
  width: 90%;
}
.el-button {
  height: 34px;
}
</style>
