<template>
  <div class="main-content">
    <div class="table-content">
      <avue-crud
        :data="data"
        :option="option"
        :search.sync="search"
        :page.sync="pageSync"
        @search-change="searchChange"
        @search-reset="searchReset"
        @on-load="onLoad"
        ref="crud"
      >
        <template slot="menuLeft">
          <div class="marginBottom20">
            <el-button type="primary" @click.stop="clickMenu('add')"
              >新增</el-button
            >
          </div>
        </template>
        <template slot="headPortrait" slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.headPortrait"
            :preview-src-list="[row.headPortrait]"
          >
          </el-image>
        </template>
        <template slot="state" slot-scope="{ row }">
          <el-switch
            :value="row.state"
            :inactive-value="2"
            :active-value="1"
            @change="handleSwitch($event, row.id)"
          ></el-switch>
        </template>

        <template slot="menu" slot-scope="{ row }">
          <el-button
            style="margin-left: 10px"
            size="small"
            type="text"
            @click.stop="clickMenu('edit', row)"
            >编辑
          </el-button>

          <!-- <el-button style="margin-left:10px;"
                            size="small"
                            type="text"
                            @click.stop="clickMenu('reset',row)">密码重置</el-button>

                    <el-button style="margin-left:10px;"
                            size="small"
                            type="text"
                            @click.stop="clickMenu('auth',row)">授权</el-button> -->

          <el-button
            style="margin-left: 10px"
            size="small"
            type="text"
            @click.stop="clickMenu('cel', row)"
            >删除</el-button
          >
        </template>

        <!-- <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary"
                               icon="el-icon-circle-plus-outline"
                               size="small"
                               v-if="type=='add'"
                               @click="$refs.crud.rowSave()"
                    >保存</el-button>
                    <el-button type="primary"
                               size="small"
                               v-if="type=='edit'"
                               @click="$refs.crud.rowUpdate()"
                    >修改</el-button>
                    <el-button
                               icon="el-icon-circle-close"
                               size="small"
                               plain
                               @click="$refs.crud.closeDialog()"
                    >取消</el-button>
                </template> -->
      </avue-crud>
    </div>

    <Role
      v-if="dialogVisible_role"
      :dialogVisible="dialogVisible_role"
      :choiceId="choiceId"
    />
    <Edit
      v-if="dialogVisible_edit"
      :dialogVisible="dialogVisible_edit"
      :choiceId="choiceId"
    />
    <Add
      v-if="dialogVisible_add"
      :dialogVisible="dialogVisible_add"
      :choiceId="choiceId"
    />
    <LookView
      v-if="dialogVisible_view"
      :dialogVisible="dialogVisible_view"
      :choiceId="choiceId"
    />
  </div>
</template>

<script>
import Role from "@/components/system/user/role";
import Edit from "@/components/system/user/edit";
import Add from "@/components/system/user/add";
import LookView from "@/components/system/user/lookview";
import {
  passwordReset,
  userDelete,
  userlist,
  userStateUpdate,
} from "@/api/system/index";
import { mapActions } from "vuex";

export default {
  name: "role",
  components: {
    Add,
    Role,
    Edit,
    LookView,
  },
  data() {
    return {
      Buttons: [], //表格按钮
      search: {},
      data: [],
      option: {
        title: "",
        titleSize: "h3",
        page: true, // 显示分页
        card: false,
        emptyText: "暂无数据",
        stripe: false,
        showHeader: true,
        border: true,
        align: "center",
        menuAlign: "center",
        columnBtn: false, //列显隐
        refreshBtn: false, //刷新
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,

        column: [
          {
            label: "管理员姓名",
            prop: "fullName",
          },
          {
            label: "头像",
            prop: "headPortrait",
          },
          {
            label: "邮箱",
            prop: "email",
          },
          {
            label: "身份",
            prop: "roleName",
          },
          {
            label: "账号",
            prop: "username",
          },
          {
            label: "是否启用",
            prop: "state",
            // type: 'radio',
            // dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}]
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },
      ids: [], //选择数据

      dialogVisible_role: false, //用户角色管理
      dialogVisible_edit: false, //用户编辑
      dialogVisible_add: false, //用户添加
      dialogVisible_view: false, //用户查看
      choiceId: "",
      page: 1,
      pageSize: 10,
      fullName: "",
      phone: "",
      username: "",

      pageSync: {
        total: 0,
        currentPage: 1,
      },
    };
  },
  mounted() {
    let that = this;
    that.Buttons = that.$store.state.index.menuSubButtons;
    // console.log(this.Buttons)

    that.getRole(); //用户管理-角色列表
    that.getParent(); //用户管理-归属上级

    that.$root.eventHub.$on("changeRole", (n) => {
      that.dialogVisible_role = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeEdit", (n) => {
      that.dialogVisible_edit = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeAdd", (n) => {
      that.dialogVisible_add = false;
      if (n) {
        that.user_list();
      }
    });
    that.$root.eventHub.$on("changeView", (n) => {
      that.dialogVisible_view = false;
    });
  },
  methods: {
    ...mapActions(["getRole", "getParent"]),
    //用户管理-列表
    user_list() {
      userlist({
        page: this.pageSync.currentPage,
        size: this.pageSync.pageSize,
        fullName: this.fullName,
        phone: this.phone,
        username: this.username,
      })
        .then((res) => {
          this.data = res.data.records;
          this.pageSync.total = res.data.total;
          this.pageSync.currentPage = res.data.current;
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    onLoad() {
      this.user_list();
    },
    handleGrant(form) {
      this.dialogVisible_role = true;
      this.choiceId = form.id;
    },
    clickedit(form) {
      this.choiceId = form.id;
      this.dialogVisible_add = true;
    },
    clickMenu(name, form) {
      //调整新增编辑都走一个页面
      // console.log(name,1111)
      if (name == "add") {
        this.dialogVisible_add = true;
        this.choiceId = "";
        return;
      }
      //新增编辑都走新增页面，
      if (name == "cel") return this.UserDelete(form.id);
      if (name == "auth") return this.handleGrant(form);
      if (name == "edit") return this.clickedit(form);
      // if(name == '查看') return this.clickView(form);
      if (name == "reset") {
        // console.log(form)
        this.PasswordReset(form);
      }
    },
    PasswordReset(form) {
      this.$confirm(`密码重置`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            passwordReset(form.id)
              .then((res) => {
                if (res.code === 200) {
                  this.$notify({
                    title: "重置密码为123456",
                    duration: 5000,
                    type: "success",
                  });
                  this.$message.success(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    clickView(form) {
      this.choiceId = form.id;
      this.dialogVisible_view = true;
    },
    UserDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            userDelete(id == undefined ? this.ids : id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.user_list();
                  that.$message.success(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    searchChange(params, done) {
      // console.log(params)
      this.fullName = params.fullName == undefined ? "" : params.fullName;
      this.phone = params.phone == undefined ? "" : params.phone;
      this.username = params.username == undefined ? "" : params.username;
      this.user_list();
      done();
    },
    searchReset(params) {
      this.fullName = params.fullName;
      this.phone = params.phone;
      this.username = params.username;
      this.user_list();
    },
    //切换是否启用
    handleSwitch(e, id) {
      userStateUpdate({
        id: id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style scoped></style>
