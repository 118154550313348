import { get, post, put } from "./axios";
/* 登陆 */
export const login = (params) => post("/web/login", params);

// 登出
export const logout = (params) => get("/web/index/logout", params);

/* 导航菜单列表 */
export const menuList = (params) => get("/web/index/menu", params);

/* 个人信息 */
export const userinfo = (params) => get("/web/index/user/info", params);

// 修改个人信息
export const useredit = (params) => put("/web/index/user/edit", params);

// 修改个人密码
export const userpassword = (params) =>
  put("/web/index/user/password/update", params);

// 获取省市区（获取省份传0）
export const GetArea = (params) => get(`/web/tool/get/area/${params}`);

/**
 * 全局按钮控制
 * /web/index/button
 */
export const indexButton = (params) => get("/web/index/button", params);

/**
 * oss上传凭证
 * /web/index/button
 */
export const toolsts = (params) => get("/web/tool/get/sts", params);

// 修改个人信息密码
export const userPassword = (params) =>
  put("/web/index/user/password/update", params);
