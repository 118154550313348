<template>
  <div class="header">
    <div
      class="fold"
      :class="isCollapse ? 'rotate180' : 'rotate0'"
      @click="fold"
    >
      <i class="el-icon-s-fold"></i>
    </div>
    <div>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <avue-avatar :src="headPortrait"></avue-avatar>
          <span :style="{ marginLeft: '15px' }">{{ fullName }}</span>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/"> 首页 </el-dropdown-item>
          <!--                       <el-dropdown-item command="/info/index">-->
          <!--                            个人信息-->
          <!--                       </el-dropdown-item>-->
          <el-dropdown-item command="/info"> 修改个人信息 </el-dropdown-item>
          <el-dropdown-item command="/password"> 修改密码 </el-dropdown-item>
          <el-dropdown-item :divided="true" command="/login">
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { userinfo, logout } from "../../api";
import { imageUrl } from "@/config/public";
export default {
  name: "header.vue",
  props: {
    isCollapse: Boolean,
  },
  data() {
    return {
      headPortrait: "",
      userName: "",
      fullName: "",
    };
  },
  computed: {
    isImg() {
      return this.$store.state.user.userInfo.headPortrait;
    },
    isName() {
      return this.$store.state.user.userInfo.fullName;
    },
  },
  watch: {
    isImg(newName, oldName) {
      this.headPortrait = newName;
    },
    isName(newName, oldName) {
      this.fullName = newName;
    },
    immediate: true,
    // deep: true,
  },
  mounted() {
    userinfo()
      .then((res) => {
        if (res.code === 200) {
          this.headPortrait = res.data.headPortrait;
          this.userName = res.data.username;
          this.fullName = res.data.fullName;
          this.$store.commit("setUserInfo", res.data);
        }
      })
      .catch((err) => {
        // this.$message.error(err.msg)
      });

    this.$root.eventHub.$on("changeUserInfo", (n) => {
      this.headPortrait = this.$store.state.user.userInfo.headPortrait;
    });
  },
  methods: {
    fold() {
      this.$root.eventHub.$emit("changeDate", null);
    },
    handleCommand(command) {
      if (command == "/login") {
        this.$confirm(`退出系统, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          callback: (action) => {
            if (action == "confirm") {
              logout()
                .then((res) => {
                  if (res.code === 200) {
                    this.$router.push("/login");
                    this.global.Set_store();
                    this.global.SET_localStorage();
                  }
                })
                .catch((err) => {
                  this.$message.error(err.msg);
                });
            } else {
              this.$message({
                type: "info",
                message: "已取消",
              });
            }
          },
        });
      } else {
        if (command != this.$store.state.index.router) {
          this.$router.push(command);
          let title = "";
          if (command == "/") {
            title = "首页";
          } else {
            title = "修改密码";
          }
          let data = {
            title: title,
            name: "0",
            url: command,
            index: "0",
          };
          let exist = false;
          let tableTabs = this.$store.state.index.tableTabs;
          tableTabs.forEach((item) => {
            if (item.title == title) {
              data.name = item.name;
              exist = true;
            }
          });
          if (!exist) {
            tableTabs.forEach((item, index) => {
              if (index + 1 == tableTabs.length) {
                data.name = (item.name * 1 + 1).toString();
              }
            });
          }
          this.$root.eventHub.$emit("changeSubMenuIndex", "0");
          this.$root.eventHub.$emit("changeTableTabs", { type: exist, data });
        }
      }
    },
  },
};
</script>

<style scoped>
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fold {
  font-size: 30px;
}
.rotate180 {
  transition: 0.5s;
  transform: rotate(180deg);
}
.rotate0 {
  transition: 0.5s;
  transform: rotate(0deg);
}
.el-icon--right {
  margin-left: 20px;
}
.name {
  margin-left: 10px;
}
</style>
