import { get, put } from '../axios'

// 菜单管理-列表
export const systemManageList = params => get(`/web/system/menu/list`,params);

// 菜单管理-查看
export const systemManageView = params => get(`/web/system/menu/view/${params}`);

// 菜单管理-修改
export const systemManageUpdate = params => put(`/web/system/menu/update`,params);

